import Parse from 'parse';
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from "shards-react";

import DepartmentItem from '../components/administration/DepartmentItem';

import colors from "../utils/colors";

import { ReactComponent as AddDepartmentIcon } from "../images/add_a_department.svg";
import { ReactComponent as DivisorBarIcon } from "../images/edited_divisor.svg";

import DepartmentTemplate from '../assets/Murmuratto_Template_Department.csv';



import { useTranslation } from "react-i18next";
import ImportDataModal from '../components/administration/ImportDataModal';
import SettingsActionButtons from '../components/common/SettingsActionButtons';


function AdministrationDepartments(smallStats) {
  const { t } = useTranslation();
  const [username, setUsername] = useState('')
  const [title, setTitle] = useState('')
  const [ideaStage, setIdeaStage] = useState(1)
  const [category, setCategory] = useState('')
  const [canGoNext, setCanGoNext] = useState(false)
  const [canContinue, setCanContinue] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [finishedSaving, setFinishedSaving] = useState(false)
  const [categoryIsOpen, setCategoryIsOpen] = useState(false)
  const [departments, setDepartments] = useState([])
  const [newCategory, setNewCategory] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [dataCsv, setDataCsv] = useState(false)
  let currUser = Parse.User.current();
  // this.getUserName()

  const setInitialTitle = async (user) => {
    
    // return firstName
   
    setTitle('Administration > Departments')

  }

  useEffect(() => {
    // Update the document title using the browser API
    setInitialTitle()
    getCategories()
  }, []);

  const getCategories = async() => {
    const Department = Parse.Object.extend("IdeaDepartment");
    const query = new Parse.Query(Department);
    const results = await query.find();
    
    setDepartments(results)
    transformDataToTemplate(results)
  }

  const transformDataToTemplate = (results) => {
    const modifiedData = results.map((idea) => {
      const newData = {
        "DEPARTMENT ENG": idea.get("itemNameTrans").en,
        "DEPARTMENT 2ND LANGUAGE": idea.get("itemNameTrans").es,
        "DEPARTMENT LEADER": idea.get("departmentLeader"),
      }

      return(newData)
    })
    
    setDataCsv(modifiedData)
  }

  const changeIdeaStage = () => {
    const newStage = ideaStage + 1
    
    if (newStage == 0) {
      setTitle('Welcome back, ' + username+ '!')
      setIdeaStage(newStage)
    } else if (newStage == 1) {
      setTitle(t('Opportunity >') + ' Select Idea Type')
      setIdeaStage(newStage)
    } else if (newStage == 2) {
      setTitle(t('Opportunity >') + ' Innovation > Idea Details')
      setIdeaStage(newStage)
    } else if (newStage == 3) {
      setTitle(t('Opportunity >') + ' Innovation > Idea Details > Done')
      setCanSubmit(true)
    } else {
      setTitle('Welcome back, ' + username+ '!')
      setIdeaStage(0)
    }
  }

  const setFinished = () => {
    setIdeaStage(3)
  }

  const changeBtnStatus = (status) => {
    
   setCanGoNext(status)
  }

  const changeContinueBtnStatus = (status) => {
    
   setCanContinue(status)
  }

  const updateCategories = () => {
    alert('Your department was updated succesfully!') 
    setNewCategory('')
    getCategories()
  }

  const createDepartment = () => {
    const IdeaDepartment= Parse.Object.extend("IdeaDepartment");
    const ideaDepartment = new IdeaDepartment();

    ideaDepartment.set("itemNameTrans", {en: "", es: ""});
    ideaDepartment.set("show", false);
    ideaDepartment.set("itemName", 'newCategory'+Math.random())

    setNewCategory(ideaDepartment)
  }

  const uploadData = () => {
    setOpenModal(true)
  }

  const onFinish = () => {
    alert("Your data was imported succesfully!")
    setOpenModal(false)
    getCategories()
  }

  return (
  <Container fluid className="main-content-container px-4 pb-4" style={{backgroundColor: 'white'}}>
    <Row>
      {/* Page Header :: Title */}
      <Col md="10" lg="10" className="mt-4 mx-auto">
        {/* <PageTitle title={t('Welcome back, Angel')} subtitle=" " className="text-sm-left" /> */}
        <Row>
          <Col md="8" lg="8">
            <h3 className="m-auto" style={{fontWeight: 600, color: '#303030'}}>{title}</h3>
          </Col>
          
          <Col xs="12" md="2" lg="2" className="col d-flex align-items-center ml-auto">
            {/* <ButtonGroup size="sm" className="d-inline-flex mb-3 mb-sm-0 mx-auto">
              <Button theme="white" tag={NavLink} to="/submit-idea">
                {t('SUBMIT')}
              </Button>
              <Button theme="white" tag={NavLink} to="/search-idea">
                {t('SEARCH')}
              </Button>
            </ButtonGroup> */}
           {/* <Button pill onClick={() => uploadData()}>Upload Data</Button> */}
           <SettingsActionButtons uploadData={() => uploadData()} templateFile={DepartmentTemplate} dataCsv={dataCsv}></SettingsActionButtons>
          </Col>
        </Row>
        <Row>
          <Col>
            <DivisorBarIcon></DivisorBarIcon>
          </Col>
        </Row>
      </Col>
      {/* Page Header :: Actions */}
      
    </Row>

    <Row className="mt-4">
      <Col lg="10" className="m-auto">
       
        <AddDepartmentIcon className="functionalButton functionalButton mr-4 d-block mb-4" style={{minWidth: 180, maxWidth:180, overflow: 'visible'}} onClick={() => createDepartment()}></AddDepartmentIcon>
       
        {/* {ideaStage == 0 && canGoNext && <SubmitIcon className="ml-auto d-block" style={{minWidth: 140, maxWidth:140}} onClick={() => changeIdeaStage()}></SubmitIcon>}
        {ideaStage == 0 && !canGoNext && <SubmitIconNosel className="ml-auto d-block" style={{minWidth: 140, maxWidth:140}} ></SubmitIconNosel>}
        

        {ideaStage > 0 && ideaStage <= 2 && canContinue &&  <NextIconValid className="ml-auto d-block mb-4" style={{minWidth: 140, maxWidth:140}} onClick={() => changeIdeaStage()}></NextIconValid>}
        {ideaStage > 0 && ideaStage <= 2 && !canContinue && <NextIcon className="ml-auto d-block mb-4" style={{minWidth: 140, maxWidth:140}} ></NextIcon>}
        
        {ideaStage == 3  && <HomeIcon className="ml-auto d-block mb-4" style={{minWidth: 90, maxWidth:90}} onClick={() => changeIdeaStage()}></HomeIcon>} */}
      </Col>
    </Row>
    
    {/* Divider */}
    {/* <Col xs="12" md="12" style={{height: 1, width: '100%', backgroundColor: 'black', opacity: 0.2}} className="col d-flex align-items-center"></Col> */}

    {/* Categories */}

    { newCategory && 
        <Row className="mt-2">
          <Col md="12" lg="12">
            <DepartmentItem key={Math.random()} id={Math.random()} category={newCategory} isNew={true} updateCategories={updateCategories}></DepartmentItem>
          </Col>
        </Row>
    }

    { departments && departments.map((category, i) => {
      return (
        <Row className="mt-2">
          <Col md="12" lg="12">
            <DepartmentItem key={i+Math.random()} id={i+Math.random()} category={category} updateCategories={updateCategories}></DepartmentItem>
          </Col>
        </Row>
      )
    })}

  <ImportDataModal open={openModal} onFinish={() => onFinish()} dataType="department"></ImportDataModal>
    
  </Container>
  )}

AdministrationDepartments.propTypes = {
  /**
   * The small stats data.
   */
  smallStats: PropTypes.array
};

AdministrationDepartments.defaultProps = {
  signedIn: true,
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default AdministrationDepartments;
