import Parse from 'parse';
import React from "react";
import { withTranslation } from "react-i18next";
import ReactLoading from 'react-loading';
import Select from 'react-select';
import {
  Col,
  Form,
  FormFeedback,
  FormInput,
  FormRadio,
  FormTextarea,
  Row,
} from "shards-react";
import { submitOpportunity } from '../../services/ideaService.js';
import { fetchQuestions, fetchTeam } from '../../services/tableService.js';
import { authUser } from '../../services/userService.js';
import CustomFileWithProgressUpload from "../components-overview/CustomFileWithProgressUpload";
import CategorySelect from "./CategorySelect";
import IdeaFilterSelectNew from "./IdeaFilterSelectNew";
import SelectedCategoryDisplay from "./SelectedCategoryDisplay";
import Switch from "./Switch.js";
import ThankYou from "./ThankYou";

// Check if storage language is null and set default to english
var storageLanguage = localStorage.getItem('language') != null?localStorage.getItem('language'):'en';

class SubmitIdeaForm extends React.Component {
    constructor(props) {
      super(props);

      this.state = this.getInitialState()
      this.change = this.change.bind(this);
      this.setCategory = this.setCategory.bind(this);
      this.setDate = this.setDate.bind(this);
      this.setIdeaDescription = this.setIdeaDescription.bind(this);
      this.setIdeaTitle = this.setIdeaTitle.bind(this);
      this.setExpectedReturn = this.setExpectedReturn.bind(this);
      this.addAnswer = this.addAnswer.bind(this);
      this.saveIdea = this.saveIdea.bind(this);
      this.showNext = this.showNext.bind(this);
    }

    validateStageInit = () => {
      const { ideaTitle, ideaDescription, category } = this.state;
      if(ideaTitle && ideaDescription && category) return true

      return false
    }

    validateStageOne = () => {
      const { ideaType, deptName } = this.state;
      if(ideaType && (deptName && deptName !== '-1')) return true

      return false
    }

    validateStageTwo = () => {
      const { filterQAnswers, categoryQuestions } = this.state;
      const isAllRequiredFilterAnswered = filterQAnswers.every(q => !q.required || q.answer);
      const isAllRequiredCategoryQuestionsAnswered = categoryQuestions.every(q => !q.required || q.answer);
      return isAllRequiredFilterAnswered && isAllRequiredCategoryQuestionsAnswered;
    }
  
    componentDidMount() {
      window.addEventListener('beforeunload', this.handleBeforeUnload);
      this.getUserName();
      this.getUsers();
      this.fetchAllUsers();
      this.fetchUserData();
      this.fetchNewData();
      this.fetchFilterQuestions();
      this.getDate();
    }

    componentWillUnmount() {
      window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }
    
    handleBeforeUnload = (e) => {
      if (!this.state.canExit) {
          const confirmationMessage = 'Await, keep submitting the idea.';
          (e || window.event).returnValue = confirmationMessage;
          return confirmationMessage; 
      }
    }
  
    componentDidUpdate(prevProps, prevState) {
      if(this.props.currentStage === 0){
        if (prevProps.currentStage !== this.props.currentStage) {
          if (this.props.currentStage === 0) this.checkCanContinue();
          return
        }

        if (this.state.ideaTitle !== prevState.ideaTitle ||
          this.state.ideaDescription !== prevState.ideaDescription ||
          this.state.category !== prevState.category) {
            this.props.changeContinueStatus(this.validateStageInit());
          }
      }
  
      if(this.props.currentStage === 1){
        if (prevProps.currentStage !== this.props.currentStage) {
          if (this.props.currentStage === 0) this.checkCanContinue();
          return
        }
        
        if(this.state.ideaType !== prevState.ideaType ||
          this.state.deptName !== prevState.deptName) {
          this.props.changeContinueStatus(this.validateStageOne());
        }
      }
      

      //  Check if went back
      if (this.props.currentStage < prevProps.currentStage ) {
        this.setState({ didGoBack: true })
      }
      
      

      if (this.props.currentStage === 3 && prevProps.currentStage === 2) this.saveIdea()

    }
    
    getInitialState = () => {
      return {
        data:[],
        ideaQuestions: [],
        showRequiredFieldsMessage: false,
        answers:[],
        category:'',
        deptName:null,
        department:'',
        date: '',
        categoryQuestions: [],
        filterQuestions: [],
        selectedFilterQ: [],
        filterQAnswers: [],
        categoryQAnswers: [],
        ideaDescription: null,
        ideaTitle: null,
        file:null, 
        buttonNext:true,
        descriptionValid:'',
        titleValid:'',
        remainingCharacters: 250,
        remainingTitleCharacters: 30,
        visible: true,
        filterVisible: false,
        filterQuestionsVisible: false, 
        ideaQuestionsVisible: false,
        hideNextButton: false,
        userName: 'User Name',
        userDept: '',
        sectionTitle:'',
        formButtonTitle: 'Continue',
        allUsers:[],
        selectedOption: null,
        allTeamUsers: [],
        teamName:'',
        hasTeam: false,
        ideaType: '',
        expectedReturn: 0,
        options: [],
        selectedEmployees: [],
        categoryIcon: '',
        hasAttachment: false,
        hasATeam: false,
        categoryDescription: '',
        canReset: false,
        categoryId: '',
        categoryItem: '',
        showLoading: false,
        canExit: true,
        selectedValue: '',
        didGoBack: false,
        biggestStage: 0
      }
    }

    resetState = () => {
      const newState = this.getInitialState()
      this.props.isLoading(false)
      this.setState(newState, () => {})
    }

    async getUsers() {
      var query = new Parse.Query(Parse.User);
      query.notEqualTo("evaluationCriteria", []);
      const results = await query.find({ useMasterKey: true });
      
      this.setState({ allUsers: results });
     
    }

    fetchUserData() {
      fetchTeam()
        .then((results) => {
          const options = results.map((result) => ({
            value: result.id,
            label: result.fullName,
          }));
          
          this.setState({
            options: options,
          });
          
        })
        .catch((error) => {
          console.error('Error fetching team data:', error);
          this.setState({
            options: [],
          });
        });
    }

    fetchAllUsers() {
      const className = "User";

      var ItemClass = Parse.Object.extend(className);
      var query = new Parse.Query(ItemClass);
      query.limit(1000)
      .doesNotExist("deletedAt")
      .ascending("firstName").find()
      .then((results) => {
          var allUsers = [];
          results.map(user => allUsers.push({value: user.get("email"), label: user.get("firstName") + ' ' + user.get("lastName"), userObj: user}));
          this.setState({
              allTeamUsers: allUsers
          });
      }, (error) => {
          this.setState({
            allTeamUsers: []
          });
        // The object was not retrieved successfully.
        // error is a Parse.Error with an error code and message.
      });
    }

    async setNotifications() {
      const {allUsers, department, category} = this.state;
      var notified = false;
      for (var u in allUsers) {
        const evalCriteria = allUsers[u].get("evaluationCriteria");
        for (var i in evalCriteria) {
          if ((evalCriteria[i].type == "department" && evalCriteria[i].name == department) || (evalCriteria[i].type == "category" && evalCriteria[i].name == category)) {
            if (notified == false) {
              allUsers[u].increment("notificationCount");
              allUsers[u].set("notificationMessages", ["Tiene una nueva idea para manejar."]);
              allUsers[u].set("customUpdatedAt", new Date());

              await allUsers[u].save().catch(error => {
                // This will error, since the Parse.User is not authenticated
              });
            }

            notified = true;
          } 
        }
      }
    }

    sendSubmissionEmail = async() => {
      const {deptName, ideaTitle} = this.state;
      const url = 'https://' + window.location.hostname

      const currentUser = Parse.User.current();
      const userEmail = currentUser.get("email");
      const secondaryEmail = await this.getSecondaryEmail(userEmail)
      
      // Problema de seguridad // 
      return fetch('https://murmuratto-email-service.herokuapp.com/api/account/send-submission-confirmation', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          url: url,
          toEmail: userEmail,
          secondaryEmail: secondaryEmail,
          ideaTitle: ideaTitle,
          ideaCategory: deptName
        })
      });
    }
  

    getSecondaryEmail = async(primaryEmail) => {
      const User = Parse.Object.extend("User");
      const query = new Parse.Query(User);
      query.doesNotExist("deletedAt").equalTo("email", primaryEmail);
      const results = await query.find({ useMasterKey: true});
  
      if (results.length >= 1) {
        return results[0].get("secondaryEmail")
      } else {
        return ''
      }
    }

    getDate() {
      this.setState({
        date: new Date()
      });
    }

    async getUserName(user) {
      const currentUser = authUser();

      this.setState({
        userName: currentUser.fullName,
        userDept: 'Test', // Change for actual department
      });
    }

    handleSubmit() {
      const { formButtonTitle } = this.state;
      
      if (formButtonTitle == "Continue") {
        this.showNext();
      } else {
        var isInvalid = false
        this.state.answers.forEach((item,idx) => {
         
          if (item.required && !item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Por favor conteste todas las preguntas requeridas.");
        } else {
        alert("Su IDEA ha sido sometida.");
      }
    }
  }

    showNext() {
      const isShowingForm = this.state.visible;
      const isShowingFilter = this.state.filterVisible;
      const isShowingFilterQuestions = this.state.filterQuestionsVisible;
      const isShowingQuestions = this.state.ideaQuestionsVisible;

      if (isShowingForm && !isShowingFilter && !isShowingFilterQuestions && !isShowingQuestions) {

        const { category, department, descriptionValid, titleValid } = this.state;

        if (!category || !department || !descriptionValid || !titleValid) {
          alert("Por favor seleccione un departamento, una categoria y asegurese de que el titulo y la descripcion de la idea sean validos.");
        } else {
          this.setState({
            visible: !isShowingForm,
            filterVisible: !isShowingFilter,
            buttonState: false,
            hideNextButton: true,
          });
        }
      } else if (!isShowingForm && isShowingFilter && !isShowingFilterQuestions && !isShowingQuestions) {
        this.setState({
          hideNextButton: false,
          filterVisible: !isShowingFilter,
          filterQuestionsVisible: !isShowingFilterQuestions,
          buttonState: false,
        });
      } else if (!isShowingForm && !isShowingFilter && isShowingFilterQuestions && !isShowingQuestions) {
        var allAnswered = false;
        
        var isInvalid = true
        this.state.filterQAnswers.forEach((item,idx) => {
          
          if (!item.answer) {
            isInvalid = true
          }
        });
        
        if (isInvalid) {
          alert("Por favor conteste todas las preguntas.");
        } else {
          this.setState({
            filterQuestionsVisible: !isShowingFilterQuestions,
            ideaQuestionsVisible: !isShowingQuestions,
            buttonState: false,
            formButtonTitle: 'Submit',
          });
        }
      } else if (!isShowingForm && !isShowingFilter && !isShowingFilterQuestions && isShowingQuestions) {
        
        var isInvalid = false
        this.state.answers.forEach((item,idx) => {
         
          if (item.required && !item.answer) {
            isInvalid = true
          }
        });

        if (isInvalid) {
          alert("Por favor conteste todas las preguntas requeridas.");
        } else {
          this.saveIdea();
        }
      }
      
    }

    resetIdeaForm() {
      window.location.reload();
    }

    fetchNewData() {
      const className = "IdeaDepartment";

      var ItemClass = Parse.Object.extend(className);
      var query = new Parse.Query(ItemClass);

      query.find()
      .then((results) => {
          this.setState({
              data: results
          });
         
      }, (error) => {
          this.setState({ data: [] });
      });
    }

    async fetchFilterQuestions() {
      try {
        const questions = await fetchQuestions();
        this.setState({ filterQuestions: questions });
      } catch (error) {
        console.error('Error fetching question data:', error);
      }
    }
  

    handleFilterChange(newFilter) {
      const typeId = newFilter;
      const { filterQuestions } = this.state;

      var filteredData;
      if (typeId !== 'Todas') {
        filteredData = filterQuestions.filter(item => item.typeId === typeId);
      }  else {
        filteredData = filterQuestions;
      }

      this.setState({
        selectedFilterQ: filteredData
      }, this.addFilterAnswer(filteredData));
      
    }

    handleCategoryChange(selectedCategory) {
      const newCategory = selectedCategory;
      const { filterQuestions } = this.state;
      const filteredData = filterQuestions.filter(item => item.categoryId === newCategory);
      this.setState({
        categoryQuestions: filteredData
      }, this.addAnswer(filteredData));
    }

    onSubmit = e => {
      e.preventDefault();
      alert('Form submitted');
    };

    async getIdeaCount(){
      var Idea = Parse.Object.extend("Idea");
      var query = new Parse.Query(Idea);
      var count = await query.count();
     
      return ++count
    }

    async saveIdea() {
      const { deptName,  ideaDescription, file, answers, filterQAnswers, ideaTitle,  hasTeam, ideaType, expectedReturn, selectedEmployees,  categoryId } = this.state;
      this.setState({ showLoading: true, canExit: false });
      this.props.isLoading(true);
      console.log('Submitting idea...',file);
      // Prepare the opportunity data
      const opportunityData = {
        title: ideaTitle,
        description: ideaDescription,
        categoryId: categoryId,
        departmentId: deptName,
        typeId: ideaType, 
        fileId: file ? file.fileId : null,
        team: hasTeam ? selectedEmployees : [],
        expectedReturn: parseFloat(expectedReturn),
        filterAnswer: filterQAnswers,
        answer: answers,
      };
    
      try {
        await submitOpportunity(opportunityData);
       
        this.resetState();
      } catch (error) {
        console.error('Failed to submit opportunity, with error code: ' + error.message);
      }
    }
    

    resetForm(e) {
      this.setState({proponent: '', department: '', category: '', ideaDescription:'', date: new Date(),file: '', remainingCharacters: 250, descriptionValid:'', });
      window.location.reload(); 
    }

    change(event) {
      this.setState({department: event.target.value});
    }

    setCategory(categoryName, categoryIcon, description, categoryId, categoryItem) {
      this.setState({
        category: categoryName,
        categoryIcon: categoryIcon,
        categoryDescription: description,
        categoryId: categoryId,
        categoryItem: categoryItem
      })

      this.handleCategoryChange(categoryId)
      
    }

    setFilter(filter) {
      
      this.setState({ideaType: filter.id});
      this.props.changeSelectedFilter(filter.title[storageLanguage])
      this.handleFilterChange(filter.id);
    }

    setDepartment(dept) {
      this.setState({deptName: dept})
    }

    setDate(ideaDate) {
      this.setState({ date: ideaDate })
    }

    updateFilterAnswer(idx, answer) {
      const updatedFilterQAnswers = this.state.filterQAnswers.map((item, index) => {
        if (index === idx) {
          return { ...item, answer, valid: this.validateAnswer(answer, item.required) };
        }
        return item;
      });
  
      this.setState({ filterQAnswers: updatedFilterQAnswers }, this.checkCanContinue);
    }
  
    updateGeneralAnswer(idx, answer) {
      const updatedAnswers = this.state.answers.map((item, index) => {
        if (index === idx) {
          return { ...item, answer, valid: this.validateAnswer(answer, item.required) };
        }
        return item;
      });
  
      this.setState({ answers: updatedAnswers }, this.checkCanContinue);
    }
  
    handleInputChange = (event, idx, isFilter, getValue = '') => {
      const { name, value, type } = event.target;
      const answer = getValue ? getValue :  type === 'radio' ? value : event.target.value;  // Captura el valor dependiendo si es radio o input/text
      this.updateAnswer(idx, answer, isFilter);
    }
    
    updateAnswer = (idx, answer, isFilter) => {
      const answers = isFilter ? [...this.state.filterQAnswers] : [...this.state.answers];
      answers[idx] = {
        ...answers[idx],
        answer,
        valid: this.validateAnswer(answer, answers[idx].required)
      };
    
      const stateObject = isFilter ? { filterQAnswers: answers } : { answers };
      this.setState(stateObject, this.checkCanContinue);
    }
    
    validateAnswer = (answer, isRequired) => {
      return !isRequired || answer.trim().length > 0;
    }
    
    checkCanContinue = () => {
      const { answers, filterQAnswers } = this.state;
      const areRegularAnswersValid = answers.every(answer => {
        return !answer.required || (answer.answer.trim().length > 0 && answer.valid);
      });
    
      const areFilterAnswersValid = filterQAnswers.every(answer => {
        return !answer.required || (answer.answer.trim().length > 0 && answer.valid);
      });

    
      const canContinue = areRegularAnswersValid && areFilterAnswersValid;
      this.props.changeContinueStatus(canContinue);

      const areRegularAnswersRequired = answers.every(answer => {
        return !answer.required
      });
      const areFilterAnswersRequired = filterQAnswers.every(answer => {
        return !answer.required 
      });

      const showRequiredFieldsMessage = areRegularAnswersRequired && areFilterAnswersRequired;
      this.setState({
        showRequiredFieldsMessage: !showRequiredFieldsMessage
      });
    };
  
  
  setIdeaDescription(event) {
    const description = event.target.value;
    const maxCharacters = 250;
    const maxWords = 30;

    let truncatedDescription = description.slice(0, maxCharacters);
    let wordsArray = truncatedDescription.split(/\s+/);
    let wordCount = wordsArray.length;

    if (wordCount > maxWords) {
        truncatedDescription = wordsArray.slice(0, maxWords).join(' ');
        wordCount = maxWords;
    }

    const charCount = maxCharacters - truncatedDescription.length;
    let isValid = null;

    if (charCount < 0 && truncatedDescription.length > 0) {
      isValid = false;
    } else if (charCount >= 0 && truncatedDescription.length > 0) {
      isValid = true;
    } else {
      isValid = null;
    }

    if (wordCount > maxWords) {
      alert('Your description should contain 30 words or less.');
    } else {
      this.setState({
        descriptionValid: isValid,
        ideaDescription: truncatedDescription,
        remainingCharacters: charCount
      });
    }
  }

  setIdeaTitle(event) {
    const inputText = event.target.value;
    const maxCharacters = 30;
    const maxWords = 10;
    
    let truncatedText = inputText.slice(0, maxCharacters);
    let wordsArray = truncatedText.split(/\s+/);
    let wordCount = wordsArray.length;

    if (wordCount > maxWords) {
        truncatedText = wordsArray.slice(0, maxWords).join(' ');
        wordCount = maxWords;
    }

    const remainingCharacters = maxCharacters - truncatedText.length;
    const isCharacterValid = truncatedText.length <= maxCharacters;
    const isWordCountValid = wordCount <= maxWords;
    
    this.setState({
        titleValid: isCharacterValid && isWordCountValid,
        ideaTitle: truncatedText,
        remainingTitleCharacters: remainingCharacters
    });
}

  
  countWords(str) { 
    return str.split(" ").length;
  }

  setExpectedReturn(event) {
    const amount = event.target.value;
    this.setState({ expectedReturn: amount })
  }

  getFullName(result) {
    if (result) return result.get("firstName") + ' ' + result.get("lastName");
  }

  addAnswer(filteredQuestions) {
    filteredQuestions.map((item, idx) => (
      this.setState((prevState) => ({
        answers: [...prevState.answers, {question:item.question, answer:"", required: item.required}],
      }))
    ))
  }

  addFilterAnswer(filteredData) {
    var newItems = [];
    filteredData.forEach((item, idx) => {
      const question = item.transcription
      const validQuestion = question['en']
      const isRequired = item.required
      newItems.push({question: validQuestion, answer:'', required: isRequired});
    })

    this.setState({ filterQAnswers: newItems}, () => {});
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };

  selectEmployees = selectedEmployees => {
    this.setState( { selectedEmployees } );
  };

  render() {
      const {showLoading, hasTeam,  selectedFilterQ, categoryQuestions, remainingCharacters, descriptionValid,ideaDescription, ideaTitle, titleValid, remainingTitleCharacters, options } = this.state
      const { currentStage } = this.props;
      const formVisibilityState = currentStage === 0 ? 'block' : 'none';
      const filterVisibilityState = currentStage === 1 ? 'block' : 'none';
      const filterQuestionVisibilityState = currentStage === 2 && showLoading === false? 'block' : 'none';
      const loadingVisibilityState = currentStage === 3 && showLoading ? 'block' : 'none';
      const thankYouVisibilityState = currentStage === 3 ? 'block' : 'none';

      const { t } = this.props;

      return(
      <div className="edit-user-details">
          <Form className="py-4"
          onSubmit={this.onSubmit}
          noValidate
          >
            <div style={{display: formVisibilityState}}>
            <h6 style={{fontWeight: 500, color: '#303030'}}>{t('Choose how to contribute!')}</h6>
            {/* Categoria */}
            <Col md="12" className="form-group">
                <CategorySelect setCategory={this.setCategory} setSelectedValue={(myvalue) => this.setState({selectedValue: myvalue})} selectedValue={this.state.selectedValue}/>
            </Col>
            <br/>
            
            <Row form>
              <Col md="6">
              
                <SelectedCategoryDisplay categoryItem={this.state.categoryItem} categoryName={this.state.categoryIcon} categoryDescription={this.state.categoryDescription} setCategory={this.setCategory}></SelectedCategoryDisplay>
              </Col>
              
              {/* Idea Description */}
              <Col md="6" className="form-group">
                {/* <label htmlFor="ideaTitle">{t("SUBMIT_IDEA_IdeaTitle")}</label> */}
                <h6 style={{fontWeight: 500,  color: '#303030'}}>{t("SUBMIT_IDEA_IdeaTitle")+"*"}</h6>
                <FormInput
                id="userBio"
                placeholder={t('SUBMIT_IDEA_IdeaSubTitle')}
                value={ideaTitle}
                onChange={this.setIdeaTitle}
              
                />
                { titleValid &&
                <FormFeedback 
                  valid={titleValid}
                  invalid={!titleValid}>
                  {remainingTitleCharacters} {t("SUBMIT_IDEA_RemainingCharacters")}
                </FormFeedback>
                }
                <br/>
                {/* <label htmlFor="userBio">{t("SUBMIT_IDEA_IdeaDescription")}</label> */}
                <h6 style={{fontWeight: 500,  color: '#303030'}}>{t("SUBMIT_IDEA_IdeaDescription")+"*"}</h6>
                <FormTextarea
                  style={{ minHeight: "120px" }}
                  id="userBio"
                  placeholder={t("SUBMIT_IDEA_TitlePlaceholder")}
                  value={ideaDescription}
                  onChange={this.setIdeaDescription}
                />
                {ideaDescription && 
                <FormFeedback 
                  valid={descriptionValid}
                  invalid={!descriptionValid}>
                  {remainingCharacters} {t("SUBMIT_IDEA_RemainingCharacters")}
                </FormFeedback>}
                <br/>
                <Switch 
                  isOn={hasTeam}
                  handleToggle={() => this.setState({hasTeam: !hasTeam})}
                  onColor="#633FDA"
                  title={t("Add team members and attachments")}
                />
              
                
                <br/>
                {this.state.hasTeam &&
                  <div>
                    <div >
                    <h6 style={{fontWeight: 500,  color: '#303030', display: 'inline-block'}}>{t("Add an attachment")}</h6>
                      <CustomFileWithProgressUpload
                      onProgressUpdate={(progress) => {
                        if (progress < 100) {
                          this.props.changeContinueStatus(false);
                        } else {
                          this.props.changeContinueStatus(this.validateStageInit())
                        }
                      }}
                      onFileUpload={(file) => {
                        this.setState({ file })
                      }}
                      maximumSizeFile={10} />
                    </div>
                    
                      <br/>

                  {/* Team */}
                  
                    <div>
                      
                    <h6 style={{fontWeight: 500,  color: '#303030', display: 'inline-block'}}>{t("Add a team")}</h6>
                    <Select
                        value={this.state.selectedEmployees}
                        myKey={'999888'}
                        onChange={this.selectEmployees}
                        options={options}
                        isMulti
                        placeholder={t('Type [First Name] [Last Name]')}
                    />
                    </div>
                    <br/>
                  </div>
                  }
              </Col>
            </Row>
            </div>
              
            <div style={{display: filterVisibilityState}}>
              <Row form>
                <Col md="12" className="form-group">
                  <IdeaFilterSelectNew setFilter={(e) => {this.setFilter(e)}} setDepartment={(e) => {this.setDepartment(e)}}/>
                </Col>
              </Row>
            </div>

            <div style={{display: thankYouVisibilityState}}>
              {!showLoading && <ThankYou></ThankYou>}
            </div>

            {/* Select IDEA Filter Visibility State */}
            <div style={{display: filterQuestionVisibilityState}}>
            
            <Row form>
              <Col lg="12">
                <Row form>
                  {selectedFilterQ.map((item,idx) =>
                    <Col key={idx} md="6" className={"mt-4 pr-4"}>
                      <h6 style={{fontWeight: 500,  color: '#303030', fontSize: 14}}>{ item.required?item.transcription[storageLanguage]+" *":item.transcription[storageLanguage]}</h6>
                      {item.field?
                      <FormTextarea
                        style={{ minHeight: "80px" }}
                        required={item.required ? true : false}
                        id="filterQuestion"
                        placeholder={t('ANSWER')}
                        onChange={(event) => this.handleInputChange(event, idx, true)}
                      />
                      :
                      <div>
                        <FormRadio
                          inline
                          name={"filter" + idx}
                          id={"filterYes" + idx}
                          required={item.required ? true : false}
                          value="yes"
                          checked={this.state.filterQAnswers[idx].answer === 'yes'}
                          onChange={(event) => this.handleInputChange(event, idx, true, 'yes')}
                        >
                          Yes
                        </FormRadio>
                        <FormRadio
                          inline
                          name={"filter" + idx}
                          id={"filterNo" + idx}
                          required={item.required ? true : false}
                          value="no"
                          checked={this.state.filterQAnswers[idx].answer === 'no'}
                          onChange={(event) => this.handleInputChange(event, idx, true, 'no')}
                        >
                          No
                        </FormRadio>
                      </div>
                      }
                      <br/>
                    </Col>
                  )}
                  </Row>
                  
                </Col>
                </Row>
                <Row form>
                <Col lg="12">
                  <Row form>
                    {categoryQuestions.map((item,idx) =>
                      <Col key={idx} md="6" className="mt-4 pr-4">
                       <h6 style={{fontWeight: 500, color: '#303030', fontSize: 14}}>
                          {item.required 
                            ? (item.transcription && item.transcription[storageLanguage] ? item.transcription[storageLanguage] : item.question) + " *"
                            : (item.transcription && item.transcription[storageLanguage] ? item.transcription[storageLanguage] : item.question)
                          }
                        </h6>
                        {item.field ?
                          <FormTextarea
                            required={item.required ? true : false}
                            style={{ minHeight: "80px" }}
                            id="ideaQuestion"
                            placeholder={t('ANSWER')}
                            onChange={(event) => this.handleInputChange(event, idx, false)}
                          />
                        :
                        <div>
                             <FormRadio
                              inline
                              required={item.required ? true : false}
                              name={"type" + idx}
                              id={"typeYes" + idx}
                              value="yes"
                              checked={this.state.answers[idx].answer === 'yes'}
                              onChange={(event) => this.handleInputChange(event, idx, false, 'yes')}
                            >
                              Yes
                            </FormRadio>
                            <FormRadio
                              inline
                              required={item.required ? true : false}
                              name={"type" + idx}
                              id={"typeNo" + idx}
                              value="no"
                              checked={this.state.answers[idx].answer === 'no'}
                              onChange={(event) => this.handleInputChange(event, idx, false, 'no')}
                            >
                              No
                            </FormRadio>
                          </div>
                        }
                        <br/>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
              {
                this.state.showRequiredFieldsMessage && <p style={{fontSize: 12, marginTop: 10}}>* Response required.</p>
              }
            </div>

            {/* Question Visibility State */}
            <div style={{display: loadingVisibilityState}}>
              <Row form>
                <Col lg="12">
                  <Row form>
                    <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80}/>
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
          </Form>
      </div>
        );
  }
}


export default withTranslation()(SubmitIdeaForm);